import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gatekeeper } from 'gatekeeper-client-sdk';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    // public static get baseURL(): string { return "http://localhost:8888/apirest_Miscertificados/default/" };
    public static get baseURL(): string { return "https://api.miscertificados.net/" };

    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient) { }


    // ESTADISTICAS    
    getEstadisticas(params) {
        return this.http.get(AppService.baseURL + 'status/' + params);
    }


    // EMPRESAS    
    getEmpresas(params) {
        return this.http.get(AppService.baseURL + 'empresa/' + params);
    }

    postEmpresa(params) {
        return this.http.post(AppService.baseURL + 'empresa/', params);
    }

    // USUARIOS    
    getUsuarios(params) {
        return this.http.get(AppService.baseURL + 'usuario/' + params);
    }

    postUsuario(params) {
        return this.http.post(AppService.baseURL + 'usuario/', params);
    }


    // CLIENTES    
    getClientes(params) {
        return this.http.get(AppService.baseURL + 'cliente/' + params);
    }

    postClientes(params) {
        return this.http.post(AppService.baseURL + 'cliente/', params);
    }


    // INSTRUCTORES
    getInstructor(params) {
        return this.http.get(AppService.baseURL + 'instructor/' + params);
    }

    postInstructor(params) {
        return this.http.post(AppService.baseURL + 'instructor/', params);
    }

    // TIPO CURSO
    getTipoCurso(params) {
        return this.http.get(AppService.baseURL + 'tipo_curso/' + params);
    }

    postTipoCurso(params) {
        return this.http.post(AppService.baseURL + 'tipo_curso/', params);
    }


    // CATEGORIAS
    getCategoria(params) {
        return this.http.get(AppService.baseURL + 'categoria/' + params);
    }

    postCategoria(params) {
        return this.http.post(AppService.baseURL + 'categoria/', params);
    }

    // TIPO CURSO PLANTILLA
    getTipoCursoPlantilla(params) {
        return this.http.get(AppService.baseURL + 'tipo_curso_plantilla/' + params);
    }

    postTipoCursoPlantilla(params) {
        return this.http.post(AppService.baseURL + 'tipo_curso_plantilla/', params);
    }


    // INSTITUTO
    getInstituto(params) {
        return this.http.get(AppService.baseURL + 'instituto/' + params);
    }

    postInstituto(params) {
        return this.http.post(AppService.baseURL + 'instituto/', params);
    }

    // INSTITUTO CONTRATO
    getInstitutoContrato(params) {
        return this.http.get(AppService.baseURL + 'instituto_contrato/' + params);
    }

    postInstitutoContrato(params) {
        return this.http.post(AppService.baseURL + 'instituto_contrato/', params);
    }

    //  CURSOS
    getCurso(params) {
        return this.http.get(AppService.baseURL + 'curso/' + params);
    }
    getCursotesting(params) {
        return this.http.get(AppService.baseURL + 'cursotesting/' + params);
    }

    postCurso(params) {
        return this.http.post(AppService.baseURL + 'curso/', params);
    }

    //  CERTIFICADOS
    getCertificado(params) {
        return this.http.get(AppService.baseURL + 'certificado/' + params);
    }

    postCertificado(params) {
        return this.http.post(AppService.baseURL + 'certificado/', params);
    }

    //  CERTIFICADOS PENDIENTES
    getCertificadoPendiente(params) {
        return this.http.get(AppService.baseURL + 'certificado_pendiente/' + params);
    }

    postCertificadoPendiente(params) {
        return this.http.post(AppService.baseURL + 'certificado_pendiente/', params);
    }

    //  CERTIFICADOS EMPRESA
    getCertificadoEmpresa(params) {
        return this.http.get(AppService.baseURL + 'certificado_empresa/' + params);
    }

    postCertificadoEmpresa(params) {
        return this.http.post(AppService.baseURL + 'certificado_empresa/', params);
    }



    // SOLICITUDES    
    getSolicitudes(params) {
        return this.http.get(AppService.baseURL + 'solicitud/' + params);
    }

    postSolicitudes(params) {
        return this.http.post(AppService.baseURL + 'solicitud/', params);
    }

    // mensajeria
    getMensajeria(params) {
        return this.http.get(AppService.baseURL + 'mensajeria/' + params);
    }

    setMensajeria(params) {
        return this.http.post(AppService.baseURL + 'mensajeria/', params);
    }


    //Departamentos
    getDepartamentos() {
        return this.http.get(AppService.baseURL + 'departamento/');
    }
    getDepartamentoMunicipios(params) {
        return this.http.get(AppService.baseURL + 'departamento/' + params);
    }


    // UPLOAD IMG
    uploadImg(params) {
        return this.http.post(AppService.baseURL + 'upload_img/', params);
    }

    // ENVIO PUSH FIREBASE

    getPush(params) {
        return this.http.get(AppService.baseURL + 'notificacion_push/' + params);
    }

    postPush(params) {
        return this.http.post(AppService.baseURL + 'notificacion_push/', params);
    }

    //  OTROS
    async loginByAuth({ email, password }) {
        try {
            const token = await Gatekeeper.loginByAuth(email, password);
            // console.log(token);            
            // const token = "fasdfasdfadfafdafasf";
            localStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({ email, password }) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async Login({ email, password }) {
        let params = {
            lg: true,
            email: email,
            password: password,
        };
        //   console.log(params);
        this.http.post(AppService.baseURL + 'login', params)
            .subscribe((data) => {
                if (data["logger"] == true) {
                    // console.log(data);
                    const token = data["token"];
                    // localStorage.setItem('token', token);
                    sessionStorage.setItem('token', token);
                    this.user = data;
                    this.router.navigate(['/']);
                    this.toastr.success(data["msg"]);
                } else {
                    this.toastr.error(data["msg"]);

                }
            });
        // return this.http.post(AppService.baseURL+'login',params);
    }


    logout() {
        // localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
        this.toastr.success('Sesión Cerrada');
    }
}
